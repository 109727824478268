import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import Img from "gatsby-image"
export const query = graphql`
  query SITE_INDEX_QUERY {
    site {
      siteMetadata {
        title
        description
      }
    }
    allMdx(
      sort: { fields: [frontmatter___date], order: DESC }
      filter: { frontmatter: { published: { eq: true } } }
    ) {
      nodes {
        id
        excerpt(pruneLength: 250)
        frontmatter {
          title
          date
          image {
            childImageSharp {
              fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        slug
      }
    }
  }
`

const blog = ({ data }) => {
  return (
    <Layout>
      <div className="mt-48">
        <div className="grid grid-cols-1 md:grid-cols-2 px-8 md:px-16 gap-y-4 mt-8 max-w-screen-xl mx-auto">
          {data.allMdx.nodes.map(({ excerpt, frontmatter, slug }) => (
            <BlogCard
              key={slug}
              title={frontmatter.title}
              slug={slug}
              image={frontmatter.image.childImageSharp.fluid}
              summary={excerpt}
            />
          ))}
        </div>
      </div>
    </Layout>
  )
}

export default blog

const BlogCard = ({ title, summary, slug, image }) => {
  return (
    <div className="space-y-3 p-4">
      <Link to ={slug}>
        <Img fluid={image} />
      </Link>
      <Link to={slug} className="pt-4 font-bold">
        <h2 className={"mt-4"}>
          {title}
        </h2>
      </Link>
      <p className="text-xs">{summary}</p>
    </div>
  )
}
